<template>
  <van-dialog
    :value="value"
    @input="close"
    className="h5-share-dialog"
    close-on-click-overlay
    overlayClass="h5-share-over"
    @closed="close"
    :show-confirm-button="false"
  >
    <div class="share-tip pd20">
      <div class="content">
        <slot>
          <div class="item def-center">1.点击右上角...分享</div>
          <div class="item df-center">
            2.发送给朋友<zm-icon icon-class="wxFriend" class="ml10 fs24 wx-icon"></zm-icon>
          </div>
          <div class="item df-center">3.分享到朋友圈<zm-icon icon-class="wxQuan" class="ml10 fs28"></zm-icon></div>
        </slot>
      </div>
    </div>
  </van-dialog>
</template>

<script>
export default {
  name: 'ShareTip',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    close() {
      this.$emit('input', false);
    }
  }
};
</script>
<style lang="scss">
.h5-share-dialog {
  top: 200px !important;
}
.h5-share-over {
  &::before {
    content: '';
    position: absolute;
    right: 19.85px;
    top: 15.15px;
    width: 58.15px;
    height: 74.35px;
    background-image: url('~@/assets/img/over-arrow.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 50%;
  }
}
.share-tip {
  line-height: 2;
  .wx-icon {
    color: #09bb07;
  }
}
</style>
