<template>
  <zmAppPage :showHeaderLeft="true" :show-header-right="true" title="APP下载">
    <div class="extend page-bg down-load">
      <div class="logo">
        <span class="vm ml10">{{appTitle}}英语</span>
      </div>
      <div class="flex1 center extend">
        <div class="inner">
          <div class="color-primary fs18 tc">《{{appTitle}}平台》</div>
          <p class="mt10">{{appTitle}}科技致力整合小学英语，小学数学等学习资源，开发相关学习软件，方便学生及家长。</p>
          <p class="mt10">
            {{appTitle}}科技同时还集合了多位985,211名校毕业的数学专业研究生等进行数学逻辑思维锻炼方法的研究，帮助天生逻辑思维不高的孩子，通过系统的锻炼与练习，能够快速理解题目背后的逻辑，掌握分析问题，解决问题的能力。
          </p>
          <div class="tc">
            <img src="schoolImg" alt="" class="wp50" />
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="inner">
          <van-button type="info" block @click="onDownLoad">
            <zm-icon icon-class="android" class="vm"></zm-icon>
            <span class="ml5 vm">下载app</span>
          </van-button>
        </div>
      </div>
    </div>
    <h5-share v-model="showTip" v-if="showTip">
      <div>1.点击右上角...</div>
      <div>2.在浏览器打开</div>
      <div>3.点击“下载app”</div>
    </h5-share>
  </zmAppPage>
</template>

<script>
import schoolImg from '@/assets/img/school.png';
import h5Share from '@/pages/index/components/Mine/components/h5Share';
import config from '@/config';
import * as tools from '@/utils/src/tools';
export default {
  name: 'DownLoad',
  components: { h5Share },
  data() {
    return {
      schoolImg,
      appTitle: config.appTitle,
      showTip: false
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    onDownLoad() {
      const os = tools.os;
      if (os.isWechat) {
        return (this.showTip = true);
      }
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = config.downAppUrl;
      link.setAttribute('download', 'yqxen.apk' || '下载.apk');
      console.log('yqxen');
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 20000);
    }
  }
};
</script>
<style lang="scss" scoped>
.down-load {
  display: flex;
  flex-direction: column;
  position: relative;
  //   justify-content: center;
  background: url('~@/assets/img/page-bg.jpg') repeat-y;
  background-size: 100% auto;
  .center {
    margin-top: 20px;
    background: url('~@/assets/img/jj.png') no-repeat center top;
    background-size: 100%;
    padding: 28px 32px 0;
    p {
      text-indent: 2em;
    }
    .inner {
      padding: 30px;
      height: 100%;
      background: url('~@/assets/img/school.png') no-repeat right bottom #fff;
      background-size: 200px;
      border-radius: 0 0 10px 10px;
    }
  }
  .footer {
    height: 150px;
    // background: url('~@/assets/img/down-bg.png') no-repeat center top;
    // background-size: cover;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    width: 100%;
    padding:0 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    // .inner {
    //   padding: 0 15px;
    // }
  }
}
.logo {
  font-size: 40px;
  line-height: 45px;
  color: #fff;
  text-align: center;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  font-family: 'Source Han Sans CN,Courier New', Courier, monospace;
  &:before {
    content: '1';
    width: 45px;
    height: 45px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
    background: #ffb73a;
    transform: rotate(15deg);
  }
}
</style>
